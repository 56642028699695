import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import css from "./quote.module.css";
import CaseType from "../../components/casetype/casetype";
import classnames from "classnames";
import Jurisdiction from "../../components/selectors/jurisdiction/jurisdiction";
import store from "../../redux/store";
import { jurisdictions } from "../../components/shared/data";
import PropTypes from "prop-types";
import Button from "../../components/button/button";
import {
  OneSmall,
  TwoSmall,
  ThreeSmall,
  OneLarge,
  TwoLarge,
  ThreeLarge,
} from "../../components/shared/imgs/index";
import { getLenders, storeQuoteRegion, validateQuote } from "../../redux/actions/actions";
import AuthCheck from "../../components/authcheck/authCheck";
import authState from "../../components/authcheck/authState";
import Layout from '../../layout';

// case type components
import Sale from "./case-type/sale";
import SaleAndPurchase from "./case-type/sale-and-purchase";
import Purchase from "./case-type/purchase";
import Remortgage from "./case-type/remortgage";
import TransferOfEquity from "./case-type/transfer-of-equity";
import ValidationGroup from "../../components/validation-group";
import { QuoteType } from "../../data/quote-type";
import Panels from "./components/panels";
// end components

function Quote({ getAccessToken }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [jurisdictionPreferenceArr, setJurisdictionPreferenceArr] = useState(
    []
  );
  const caseType = useSelector((store) => store.quote.type.value);
  const preferencesReceived = useSelector(
    (store) => store.preferences.loaded.value
  );
  const currentStore = useSelector((store) => store.quote.type.value);
  const jurisdictionPreference = useSelector(
    (store) => store.preferences.jurisdiction.value
  );

  const quoteReceived = useSelector((store) => store.saveQuote?.body?.caseType === caseType);

  const quoteBusy = useSelector(store => store.loaders.quoteRequestBusy)
  const siteError = useSelector(store => store.site.siteError)
  const editing = useSelector(store => store.quote.editing);
  const lenders = useSelector((store) => store.lenders.data);

  useEffect(() => {
    if (
      jurisdictionPreferenceArr.indexOf(currentStore) < 0 &&
      preferencesReceived
      && !quoteReceived
      && !editing) {
      setJurisdictionPreferenceArr([
        ...jurisdictionPreferenceArr,
        currentStore,
      ]);
      dispatch(storeQuoteRegion(jurisdictionPreference, currentStore));
    }
  }, [caseType, preferencesReceived]);

  useEffect(() => {
    setSubmitted(false);
  }, [caseType])

  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [childValidStates, setChildValidStates] = useState({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (siteError) {
      setIsLoading(false)
      setIsDisabled(false)
    } else {
      setIsLoading(quoteBusy)
      setIsDisabled(quoteBusy)
    }
  }, [quoteBusy, siteError])

  useEffect(() => {
    if (!lenders || lenders.length < 1) {
      getAccessToken().then(() => {
        dispatch(getLenders());
      });
    }
  }, [lenders])


  const validationHandler = (isValid) => {
    setChildValidStates({ ...childValidStates, [caseType]: isValid });
  }

  const childrenValid = () => {
    return !!childValidStates[caseType];
  };

  const submit = () => {
    setSubmitted(true);
    if (childrenValid()) {
      setIsLoading(true)
      getAccessToken().then(() => {
        store.dispatch(validateQuote(history, true));
      });
    }
  }

  return (
    <Layout>
      <AuthCheck requiredAuthState={authState.AUTHENTICATED} />
      <Row className={css.bottompad2x}>
          <Col md={1} xs={1}></Col>
          <Col md={10} xs={10}>
            <Row>
              <Col md={12} xs={12}>
                <div className={css.intro}>
                  GETTING A QUOTE COULDN’T BE EASIER - JUST FOLLOW THESE 3 EASY
                  STEPS
                </div>
              </Col>
            </Row>


            <Row className={classnames(css.instruction)}>
              <Col
                md={3}
                xs={12}
                className={classnames(css.leftalign, css.instructionAlign)}
              >
                <OneSmall className={css.instructionImg} />
                <div className={css.instructionText}>Select the case type</div>
              </Col>
              <Col
                md={3}
                xs={12}
                className={classnames(css.leftalign, css.instructionAlign)}
              >
                <TwoSmall className={css.instructionImg} />
                <div className={css.instructionText}>Choose the region</div>
              </Col>
              <Col
                md={6}
                xs={12}
                className={classnames(css.leftalign, css.instructionAlign)}
              >
                <ThreeSmall className={css.instructionImg} />
                <div className={css.instructionText}>
                  Enter basic property details
                </div>
              </Col>
            </Row>

            {Panels({getAccessToken})}

            <Row className={classnames(css.stepInstruction)}>
              <Col
                md={12}
                xs={12}
                className={classnames(
                  css.leftalign,
                  css.leftpad2x,
                  css.stepInstructionAlign
                )}
              >
                <OneLarge className={css.stepInstructionImg} />
                <div className={css.stepInstructionText}>
                  SELECT THE CASE TYPE
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} xs={12}>
                <CaseType></CaseType>
              </Col>
            </Row>

            <Row className={classnames(css.stepInstruction)}>
              <Col
                md={12}
                xs={12}
                className={classnames(
                  css.leftalign,
                  css.leftpad2x,
                  css.stepInstructionAlign
                )}
              >
                <TwoLarge className={css.stepInstructionImg} />
                <div className={css.stepInstructionText}>CHOOSE THE REGION</div>
              </Col>
            </Row>

            <Row>
              <Col
                md={12}
                xs={12}
                className={classnames(
                  css.leftalign,
                  css.leftpad2x,
                  css.toppad2x,
                  css.bottompad5x
                )}
              >
                <Jurisdiction
                  id="jurisdiction"
                  datatestid="jurisdiction"
                  persistAction={storeQuoteRegion}
                  subsection={caseType}
                  storelocation={(store) => store.quote[currentStore].jurisdiction.value}
                  jurisdictions={jurisdictions} />
              </Col>
            </Row>

            <Row className={classnames(css.stepInstruction)}>
              <Col
                md={12}
                xs={12}
                className={classnames(
                  css.leftalign,
                  css.leftpad2x,
                  css.stepInstructionAlign
                )}
              >
                <ThreeLarge className={css.stepInstructionImg} />
                <div className={css.stepInstructionText}>
                  ENTER PROPERTY DETAILS
                </div>
              </Col>
            </Row>

            <ValidationGroup submitted={submitted} validationHandler={validationHandler}>
              {caseType == QuoteType.SALE && <Sale />}
              {caseType == QuoteType.PURCHASE && <Purchase />}
              {caseType == QuoteType.REMORTGAGE && <Remortgage />}
              {caseType == QuoteType.TRANSFER_OF_EQUITY && <TransferOfEquity />}
              {caseType == QuoteType.SALE_AND_PURCHASE && <SaleAndPurchase />}
            </ValidationGroup>

            <Row className={css.label}>
              <Col md={12} xs={12} className={classnames(css.leftalign)}>
                <Button
                  btnClass={css.next}
                  id="quote-next"
                  datatestid="quote-next"
                  type="button"
                  size="lg"
                  onClick={submit}
                  disabled={isDisabled}
                  isLoading={isLoading}
                  text="NEXT" />
              </Col>
            </Row>
          </Col>
          <Col md={1} xs={1}></Col>
        </Row>
    </Layout>
  );
}

Quote.propTypes = {
  getAccessToken: PropTypes.func.isRequired,
};

export default Quote;
