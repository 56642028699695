import { call, put, takeLatest, fork } from "redux-saga/effects";
import * as types from "../../actions/types";
import getRequest from "../../../services/getRequest"
import showError from "../utils/error";

function* fetchPanels(action) {
  try {
    yield put({ type: types.SITE_BUSY, payload: true });

    var url = `${window.config.apiBaseUrl}/panels?pageSize=6`
    if (action.payload) url += '&searchTerm=' + action.payload
    const res = yield call(getRequest, url)

    const { data: payload, status } = res;

    const panels = payload.items.map((panel) => {
      return {
        id: panel.id,
        name: panel.name,
        lastModifiedDate: panel.lastModifiedDate,
        ownerType: panel.ownerType,
        type: panel.type
      }
    })

    if (status === 200) {
      const withDefaultPanels = [{id: null, name: "Select Core", type: "Select"}].concat(panels);
      yield put({ type: types.SET_PANELS, payload: withDefaultPanels });
      yield put({ type: types.SITE_ERROR, payload: false });
    } else {
      yield put({ type: types.SITE_ERROR, payload: true });
      yield showError(res);
    }
    yield put({ type: types.SITE_BUSY, payload: false });
  } catch (e) {
    yield showError(e);
    yield put({ type: types.SITE_ERROR, payload: true });
  }
}

function* saga() {
  yield takeLatest(types.GET_PANELS, fetchPanels);
}

const panelsSaga = fork(saga);

export {
  panelsSaga as default,
  fetchPanels,
  saga
};
