import { SET_PANELS } from '../actions/types'

const initialState = {
  data: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PANELS:
      return {
        data: [...action.payload]
      }
    default:
      return state
  }
}
