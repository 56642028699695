import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { RESET_QUOTE_DATA, RESET_INSTRUCT_DATA, RESET_STORE } from "../actions/types";
import instructReducer from "./instructReducer";
import quoteReducer from "./quoteReducer";
import settingsReducer from "./settingsReducer";
import preferenceReducer from "./preferenceReducer";
import siteReducer from "./siteReducer";
import loadingReducer from "./loadingReducer";
import uinsureQuoteReducer from "./uinsureQuoteReducer";
import emulateReducer from "./emulateReducer";
import lendersReducer from "./lendersReducer";
import panelsReducer from "./panelsReducer";

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['emulateUser'] // only emulateUser will be persisted
};


const appReducer = combineReducers({
  instruct: instructReducer,
  preferences: preferenceReducer,
  quote: quoteReducer,
  settings: settingsReducer,
  site: siteReducer,
  loaders: loadingReducer,
  uinsure: uinsureQuoteReducer,
  emulateUser: emulateReducer,
  lenders: lendersReducer,
  panels: panelsReducer
});

const rootReducer = (state, action) => {
  let clearState = { ...state };
  if (action.type === RESET_QUOTE_DATA) {
    clearState = {
      ...state,
      quote: undefined,
      instruct: undefined,
      site: undefined,
      loaders: undefined,
      uinsure: undefined,
    };
  } else if (action.type === RESET_STORE) {
    clearState = {
      ...state,
      quote: undefined,
      preferences: undefined,
      instruct: undefined,
      site: undefined,
      loaders: undefined,
      uinsure: undefined,
      panels: undefined
    };
  } else if (action.type === RESET_INSTRUCT_DATA) {
    clearState = {
      ...state,
      instruct: undefined,
    };
  }

  return appReducer(clearState, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer;
