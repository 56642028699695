import { call, put, takeLatest, fork } from "redux-saga/effects";
import * as types from "../../actions/types";
import getRequest from "../../../services/getRequest"
import showError from "../utils/error";

function* fetchLenders(action) {
  try {
    yield put({ type: types.SITE_BUSY, payload: true });

    const res = yield call(getRequest, `${window.config.apiBaseUrl}/lenders`)

    const { data: payload, status } = res;
    const mappedLenders = payload.map((lender) => {
      return {
        value: lender.id + '',
        name: lender.name,
        isSpecialist: lender.isSpecialist
      }
    })

    if (status === 200) {
      yield put({ type: types.SET_LENDERS, payload: mappedLenders });
      yield put({ type: types.SITE_ERROR, payload: false });
    } else {
      yield put({ type: types.SITE_ERROR, payload: true });
      yield showError(res);
    }

    yield put({ type: types.SITE_BUSY, payload: false });
  } catch (e) {
    yield showError(e);
    yield put({ type: types.SITE_ERROR, payload: true });
  }
}

function* saga() {
  yield takeLatest(types.GET_LENDERS, fetchLenders);
}

const lendersSaga = fork(saga);

export { lendersSaga as default, fetchLenders, saga };
