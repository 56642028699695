import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPanels, storeQuotePanel } from "../../../redux/actions/actions";
import Row from "react-bootstrap/Row";
import classnames from "classnames";
import css from "./panels.module.css";
import FormControl from "react-bootstrap/FormControl";

const Panels = ({ getAccessToken }) => {
  const dispatch = useDispatch();

  const panels = useSelector((store) => store.panels);

  const [showPanels, setShowPanels] = useState(undefined);
  const [searchInput, setSearchInput] = useState("");

  const caseType = useSelector((store) => store.quote.type.value);
  const currentStore = useSelector((store) => store.quote.type.value);
  const selectedPanel = useSelector((store) => store.quote[currentStore]?.panel);

  useEffect(() => {
    const fetchData = () => {
      getAccessToken().then(() => {
        dispatch(getPanels(searchInput));
      });
    };

    if (searchInput === "") {
      fetchData();
    } else {
      const debounceTimer = setTimeout(() => {
        fetchData();
      }, 500);

      return () => clearTimeout(debounceTimer);
    }
  }, [searchInput, dispatch]);

  useEffect(() => {
    if (!selectedPanel) {
      dispatch(storeQuotePanel(null, caseType))
    }
  }, [caseType])

  useEffect(() => {
    if(showPanels === undefined) {
      if (panels?.data !== undefined && panels?.data?.length !== 0){
        setShowPanels(true);
      }
    }
  }, [panels]);

  const formatter = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return (
    <>
      {showPanels === true ? (
        <div>
          <Row className={classnames(css.panelInstruction)}>
            <div className={classnames(css.panelInstructionText)}>
              SELECT PANEL
            </div>
          </Row>
          <Row className={classnames(css.panelDescription)}>
            If you would like to use one of your custom panels to quote please choose one below. If you do not choose a custom panel, we will use the standard Select panel.
          </Row>
          <Row>
            <FormControl
              style={{ marginTop: "10px" }}
              type="text"
              value={searchInput}
              onChange={(evt) => setSearchInput(evt.target.value)}
              placeholder={"Search for a panel by name"}
            />
            <div className={classnames(css.panelBody)}>
              {panels.data.map((panel) => (
                <div
                  key={panel.id}
                  className={classnames(css.panelButton, (selectedPanel?.value === panel.id ? css.selectedPanelButton : ""))}
                  name="panel"
                  onClick={() => {
                    const id = panel.id === selectedPanel?.value ? null : panel.id;
                    dispatch(storeQuotePanel(id, caseType));
                  }}>
                  <div style={{ gap: "10px" }}>
                    <p style={{ alignSelf: "flex-start" }}>{panel.name}</p>
                    <p style={{ alignSelf: "flex-end" }}>
                      Last updated:
                      {panel.lastModifiedDate
                        ? " " +
                          formatter.format(new Date(panel.lastModifiedDate))
                        : "N/A"}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Row>
        </div>
      ) : null}
    </>
  );
};

export default Panels;
